<template>
  <form class="form-cms mb-2" @submit.prevent="updateRecipe">
    <div class="form-control">
      <label for="recipe-name">Nombre de la receta:</label>
      <input
        type="text"
        name="recipe-name"
        placeholder="Nombre de receta"
        v-model="currentMainRecipe.nombre"
      />
    </div>
    <div class="fields-flex">
      <div class="form-control">
        <label for="portion-recipe">Porciones:</label>
        <input
          type="number"
          step="0.01"
          name="portion-recipe"
          placeholder="Porción"
          v-model.number="currentMainRecipe.porciones"
        />
      </div>
      <div class="form-control">
        <label for="time-recipe">Tiempo:</label>
        <div class="d-flex gap-0-5">
          <input
            type="number"
            step="0.01"
            name="time-recipe"
            placeholder="Tiempo"
            v-model.number="currentMainRecipe.timeNumber"
          />
          <select name="time-recipe" v-model="currentMainRecipe.times">
            <option value="">Seleccionar</option>
            <option value="minutos">Minutos</option>
            <option value="seg">Segundos</option>
            <option value="hora">Hora</option>
            <option value="horas">Horas</option>
          </select>
        </div>
      </div>
    </div>
    <div class="fields-flex mb-1">
      <div class="">
        <div class="form-control">
          <label for="size-recipe">Medida:</label>
          <select
            name="size-recipe"
            default="imagen"
            ref="selectedRecipe"
            @change="changeImage"
          >
            <option value="imagen">Principal (540x675px)</option>
            <option value="imagen2">Mediana (330x264px)</option>
            <option value="imagen3">Pequeña (264x330px)</option>
          </select>
        </div>
        <div class="image-item text-center mt-1">
          <img
            v-if="isNewRecipe"
            :src="imagesRecipes[currentOption]?.localImage"
            :alt="currentMainRecipe.nombre"
            :title="currentMainRecipe.nombre"
            onerror="this.onerror=null;this.src='/images/emptys/empty-product.svg';"
          />
          <img
            v-else-if="
              !isNewRecipe &&
                !imagesRecipes[currentOption].localImage.startsWith('/')
            "
            :src="imagesRecipes[currentOption]?.localImage"
            :alt="currentMainRecipe.nombre"
            :title="currentMainRecipe.nombre"
            onerror="this.onerror=null;this.src='/images/emptys/empty-product.svg';"
          />
          <img
            v-else
            :src="
              recipeimagePathss[currentOption] +
                currentMainRecipe[currentOption]
            "
            :alt="currentMainRecipe.nombre"
            :title="currentMainRecipe.nombre"
            onerror="this.onerror=null;this.src='/images/emptys/empty-product.svg';"
          />

          <input
            v-show="false"
            ref="updateInputImage"
            type="file"
            @change="onSelectedImage"
            accept="image/png, image/jpeg, image/jpg"
          />
          <button
            type="button"
            class="btn btn-secondary mt-1"
            @click="handlerUploadImage"
          >
            <i class="fas fa-upload"></i>
            Subir imagen
          </button>
        </div>
      </div>
      <div class="">
        <div class="form-control">
          <label for="author-recipe">Autor:</label>
          <input
            type="text"
            name="author-recipe"
            placeholder="Autor"
            v-model="currentMainRecipe.autor"
          />
        </div>
        <div class="form-control">
          <label for="details-recipe">Detalles:</label>
          <textarea
            rows="6"
            name="details-recipe"
            placeholder="Detalles de la receta"
            v-model="currentMainRecipe.detalles"
          />
        </div>
        <div class="form-control form-radio">
          <label for="home-recipe">Va en el Home:</label>
          <input type="checkbox" name="home-recipe" />
        </div>
      </div>
    </div>
    <div class="buttons" v-if="!isNewRecipe">
      <button class="btn btn-primary" type="submit">
        <i class="fas fa-pen-alt"></i> Actualizar
      </button>
    </div>
  </form>

  <Message
    v-if="isNewRecipe && addRecipeShowRecipeMsg"
    :message="addRecipeMsg"
    :messageType="addRecipeStatus"
  />

  <Fab
    v-if="showFabRecipe"
    text="Receta"
    @update:add="addRecipe"
    :isLoading="isAddLoading"
  />
  <ModalUpdate
    v-if="isOpenUpdateModal"
    :step="updateRecipeModalStep"
    :message="updateRecipeMsg"
    :success="updateRecipeStatus"
    screen="receta"
  />
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';

import useRecipe from '@/modules/cms/composables/recipes/useRecipe';

export default {
  components: {
    ModalUpdate: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "ModalUpdate" */ '@/modules/cms/components/modals/ModalUpdate.vue'
      )
    ),
    Fab: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Fab" */ '@/modules/cms/components/Fab.vue')
    ),
    Message: defineAsyncComponent(() =>
      import(/* webpackChunkName: "Message" */ '@/components/Message.vue')
    ),
  },
  setup() {
    const store = useStore();
    return {
      ...useRecipe(),
      isNewRecipe: computed(() => store.getters['cmsRecipes/isRecipeNew']),
    };
  },
};
</script>
